import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { withFormik } from "formik"
import Yup from "yup"

import HeaderRight from "./header_right"


const Wrapper = tw.div`
pb-40
mx-3
`

const Form = tw.form`
  md:flex
`

const FormCol = tw.div`
  flex
  flex-col
  mx-10
  justify-between
  md:w-6/12
`

const Label = tw.label`
  uppercase
  mb-1
  mt-3
  text-sm
`

const Input = tw.input`
  text-sm
  px-4
  py-2
  border-regray
  border
  rounded
  bg-relightbeige
  hover:border-regreen
`
const SubFormCol = tw.div`
  flex
  flex-col
`
const LargeInput = tw.textarea`
  text-sm
  px-4
  py-2
  bg-relightbeige
  border-regray
  border
  hover:border-regreen
  rounded
`

const Button = tw.button`
  text-sm
  text-relightbeige
  uppercase
  p-4
  bg-reyellow
  mt-4
  rounded
  hover:bg-relightblue
  hover:text-black



`

const UserForm = ({ values, handleChange, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormCol>
      <Label htmlFor="">name*</Label>
      <Input
        type="text"
        name="name"
        placeholder="Name"
        value={values.name}
        onChange={handleChange}
      />
      <Label htmlFor="">email address*</Label>
      <Input
        type="email"
        name="email"
        placeholder="Email"
        value={values.email}
        onChange={handleChange}
      />
      <Label htmlFor="">phone number*</Label>
      <Input
        type="tel"
        name="phone"
        placeholder="Phone"
        value={values.phone}
        onChange={handleChange}
      />
      <Label htmlFor="">current address*</Label>
      <Input
        type="text"
        name="address"
        placeholder="Address"
        value={values.address}
        onChange={handleChange}
      />
    </FormCol>
    <FormCol>
      <SubFormCol>
        <Label htmlFor="">what else should we know?</Label>
        <LargeInput
          rows="7"
          name="extra"
          value={values.extra}
          onChange={handleChange}
        />
      </SubFormCol>
      <Button type="submit">Submit</Button>
    </FormCol>
  </Form>
)

const FormikApp = withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    phone: "",
    address: "",
    extra: "",
  }),
  handleSubmit: values => {
    console.log(values)
  },
})(UserForm)

const ContactForm = () => {
  return (
    <Wrapper>
      <HeaderRight text="Contact Us" />
      <FormikApp />
    </Wrapper>
  )
}

export default ContactForm
