import React, { useState } from "react"
import styled from "@emotion/styled"
import tw from 'twin.macro'
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby'

import { useScrollPosition } from "./use_scroll_position"
import LogoSvg from '../images/logo.svg';



const LogoWrap = styled.div`
  margin-x: auto;
  padding: .2rem;
  flex: 1 1 0%;
  object-fit: cover;

  img{
    display: ${props => (props.hideOnScroll ? "none" : "inline")};
  }



`
const Logo = (props) => {

  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(({prevPos, currPos}) => {
    const isShow = currPos.y > -650
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  },
    [hideOnScroll])

  return(
    <LogoWrap hideOnScroll={hideOnScroll}>
      <Link to="/">
       <img src={LogoSvg} tw="h-full" alt="42 Lyndhurst Logo" />
      </Link>
    </LogoWrap>
  )
}

export default Logo
