import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'


const HeaderRightDiv = tw.text`
  flex
  flex-shrink
  justify-end
  text-4xl
  md:text-6xl
  lg:text-7xl
  leading-relaxed
  text-redarkbeige
  font-bold
  uppercase
  font-header
  w-full
  border-b
  border-relightnavy
`




const HeaderRight = (props) => {

  return (
    <HeaderRightDiv> {props.text} </HeaderRightDiv>
  )
}


export default HeaderRight
