import React from 'react';
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'

const Blog = () => {
    let data = useStaticQuery(graphql`
        query {
            WP_Post: allWpPost {
                edges {
                    node {
                        title
                        date
                        excerpt
                    }
                }
            }
        }
    `)

    let posts = data.WP_Post.edges;
    console.log(posts);

    return (
        <div>
            <h2>Blogs</h2>
            <ol>
            {
                posts.map((post,i) =>
                    <li key={i}>
                        <h4>{post.node.title}</h4>
                        <p>{post.node.date}</p>
                        <div dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
                    </li>
                )
            }
            </ol>
        </div>
    );
};

export default Blog;

