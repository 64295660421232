import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import ymldata from "../data/availability.yaml";




export default function AvData({ setsvg }) {

  function setColor(z) {
    const DataRows = document.getElementsByClassName("tablerow")
    let HtmlArray = Array.from(DataRows)
    Array.prototype.map.call(HtmlArray, row => {
      row.dataset.zone === z ? row.className = "tablerow is-selected" : row.className = "tablerow"
    })
  }

  const setBoth = (e) => {
    setColor(e)
    setsvg(e)
  }


  return (
    <StaticQuery
      query={graphql`
        {
          allAvailabilityYaml {
            edges {
              node {
                type
                url
                availability
                SF
                baths
                price
                number
              }
            }
          }
        }
    `}
    render={data => (

      data.allAvailabilityYaml.edges.map((unit) =>
        <tr className= "tablerow"  data-zone={unit.node.type} onClick={(e) => (setBoth(e.currentTarget.dataset.zone))} >
          <td>{unit.node.type}</td>
          <td>{unit.node.number}</td>
          <td>{unit.node.baths}</td>
          <td>{unit.node.SF}</td>
          <td>{unit.node.price}</td>
          <td>{unit.node.availability}</td>
          <td><a href={unit.node.url} target="blank">Download</a></td>
        </tr>
      )
    )}
  />
)}
