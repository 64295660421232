import React, {useState} from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { motion, useViewportScroll } from "framer-motion"

import tw from 'twin.macro'


const IntroWrapper = tw.div`
  relative
  h-auto
  box-border
  flex
  flex-col
  h-auto
`

const IntroDiv1 = tw.div`
  min-h-screen
  md:mb-10
  flex-none
  h-screen
  md:h-auto

`

const IntroDiv2 = tw.div`
  w-full
  px-6
  py-12
  md:p-6
  text-left
  absolute
  inset-0
  mt-24
  md:h-screen
`

const PropertySvg = tw.svg`
  md:pt-8
  w-full
  md:w-11/12
  text-right

`

const PropertyH2 = tw.div`
  text-rebeige
  font-serif
  pl-2
  md:pl-4
  text-base
  md:text-2xl
  pt-4
  font-semibold
  md:mt-10
  md:mb-2
`
const PropertyP =tw.div`
  text-rebeige
  pl-2
  md:pl-4
  font-sans
  font-semibold
  text-sm
  md:text-base
  italic

`

const MainIntro = () => {

    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `)

    const { scrollYProgress } = useViewportScroll()
    console.log({scrollYProgress})

  return (

      <IntroWrapper>
        <IntroDiv1>
          <img tw='object-cover h-full w-full' src="https://d2787ndpv5cwhz.cloudfront.net/e462392adf566d72276f3b81057c9fcf29e8109c.jpg" alt="" />
        </IntroDiv1>
        <IntroDiv2>
          <PropertySvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 458.57 68.39" enable-background="new 0 0 458.57 68.39" xml="preserve">
          <font horiz-adv-x="1000">
            <font-face font-family="AvenirNextCondensed-DemiBold" units-per-em="1000" underline-position="-75" underline-thickness="50"/>
            <missing-glyph horiz-adv-x="512" d="M222,117l0,67l56,0l0,-67M225,234l-2,17C223,288 227,315 236,332C241,341 247,350 254,360C261,369 271,380 282,391C293,401 301,410 308,418C315,425 320,432 323,439C326,445 329,451 330,457C331,462 332,468 332,474C332,498 325,518 310,535C295,551 277,559 256,559C235,559 218,552 203,537C188,522 178,498 174,464l-50,8C129,520 143,555 168,577C193,598 223,609 259,609C295,609 325,597 348,574C371,550 383,518 383,478C383,457 379,437 371,420C362,402 345,381 320,358C295,336 281,319 277,306C273,293 271,269 271,234M462,50l0,617l-412,0l0,-617M512,718l0,-718l-512,0l0,718z"/>
            <glyph unicode=" " horiz-adv-x="195"/>
            <glyph unicode="2" horiz-adv-x="500" d="M440,521C440,498 437,477 432,458C426,438 418,419 408,401C398,382 387,364 374,347C361,329 347,311 333,292l-146,-187l244,0l0,-105l-382,0l0,109l205,259C274,395 290,420 302,445C314,469 320,493 320,517C320,545 314,569 301,588C288,607 269,617 243,617C220,617 200,608 185,591C170,574 160,549 155,516l-113,11C51,592 73,640 110,673C147,706 193,722 248,722C279,722 306,717 330,707C353,697 373,683 390,665C406,647 418,626 427,601C436,576 440,550 440,521z"/>
            <glyph unicode="4" horiz-adv-x="500" d="M391,154l0,-154l-108,0l0,154l-256,0l0,105l214,449l150,0l0,-454l78,0l0,-100M286,591l-2,0l-151,-337l153,0z"/>
            <glyph unicode="D" horiz-adv-x="569" d="M527,353C527,241 504,154 457,93C410,31 340,0 249,0l-177,0l0,708l178,0C341,708 410,677 457,615C504,552 527,465 527,353M407,353C407,395 403,432 396,463C388,494 377,520 363,541C349,562 332,577 313,588C294,598 273,603 250,603l-57,0l0,-498l57,0C273,105 294,110 313,121C332,131 349,146 363,167C377,188 388,214 396,245C403,276 407,312 407,353z"/>
            <glyph unicode="H" horiz-adv-x="594" d="M403,0l0,314l-212,0l0,-314l-119,0l0,708l119,0l0,-287l212,0l0,287l119,0l0,-708z"/>
            <glyph unicode="L" horiz-adv-x="394" d="M72,0l0,708l121,0l0,-597l195,0l0,-111z"/>
            <glyph unicode="N" horiz-adv-x="586" d="M382,0l-201,500l-3,0l2,-500l-108,0l0,708l137,0l199,-503l3,0l-1,503l104,0l0,-708z"/>
            <glyph unicode="R" horiz-adv-x="513" d="M365,0l-110,302l-67,0l0,-302l-116,0l0,708l202,0C303,708 330,704 355,697C380,689 401,677 419,660C436,643 450,622 460,597C470,572 475,541 475,506C475,479 472,455 465,434C458,413 449,395 438,380C427,365 416,354 403,345C390,336 377,329 364,326l127,-326M357,500C357,521 354,539 348,553C342,567 334,578 325,586C315,594 304,600 292,603C280,606 268,608 256,608l-68,0l0,-218l68,0C285,390 310,399 329,418C348,436 357,463 357,500z"/>
            <glyph unicode="S" horiz-adv-x="449" d="M350,571C324,602 291,618 252,618C241,618 230,616 219,613C208,609 199,603 190,595C181,587 175,577 170,564C165,551 162,536 162,519C162,489 171,466 189,450C207,433 231,418 260,403C277,394 295,384 313,373C331,362 347,348 362,332C377,316 389,297 398,275C407,253 412,227 412,197C412,162 407,132 396,105C385,78 370,56 352,38C333,19 312,5 287,-4C262,-13 236,-18 209,-18C170,-18 133,-10 100,5C67,20 40,40 20,63l65,88C100,132 119,118 141,107C162,96 184,91 207,91C232,91 253,100 270,117C287,134 295,159 295,190C295,221 285,245 265,263C245,281 220,298 189,313C170,322 152,333 135,344C118,355 103,369 90,385C77,400 66,419 59,440C51,461 47,486 47,515C47,552 53,583 65,610C76,636 92,658 111,675C130,692 151,705 175,714C199,722 223,726 248,726C284,726 316,720 345,709C373,697 397,680 416,657z"/>
            <glyph unicode="T" horiz-adv-x="380" d="M249,606l0,-606l-118,0l0,606l-133,0l0,102l384,0l0,-102z"/>
            <glyph unicode="U" horiz-adv-x="567" d="M506,251C506,214 502,179 494,146C486,113 473,85 455,61C437,36 414,17 386,3C357,-11 323,-18 282,-18C241,-18 207,-11 179,3C151,17 128,36 111,61C93,85 80,113 73,146C65,179 61,214 61,251l0,457l120,0l0,-446C181,240 183,219 186,199C189,178 194,160 202,145C209,130 220,118 233,109C246,100 263,95 284,95C305,95 322,100 335,109C348,118 359,130 367,145C374,160 380,178 383,199C386,219 387,240 387,262l0,446l119,0l0,-457z"/>
            <glyph unicode="Y" horiz-adv-x="462" d="M289,271l0,-271l-118,0l0,271l-177,437l130,0l106,-316l3,2l108,314l127,0z"/>
          </font>

          <rect x="9.87" y="3.24" fill="none" width="100%" height="65.15"/>
          <text transform="matrix(1 0 0 1 9.8724 62.2111)" fill="#45536D" font-family="'AvenirNextCondensed-DemiBold'" font-size="62px">42 LYNDHURST</text>
          <rect x="5.72" y="3.24" fill="none" width="500" height="74.16"/>
          <text transform="matrix(1 0 0 1 5.7154 62.2111)" fill="#F4F1EC" font-family="'AvenirNextCondensed-DemiBold'" font-size="62px">42 LYNDHURST</text>
          </PropertySvg>
          <PropertyH2>Distinctive Living</PropertyH2>
          <PropertyP>Own a piece of <strong tw="font-serif">history</strong>, live in modern <strong tw="font-serif">comfort</strong></PropertyP>
        </IntroDiv2>
      </IntroWrapper>

  )
}



export default MainIntro
