import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import tw from 'twin.macro'
import styled from "@emotion/styled"

import Carousel from "./carousel"
import HeaderLeft from "./header_left"



const Wrapper = tw.div`
  flex
  flex-col
  mx-3
  my-auto

`

const FlexBox = tw.div`
  flex
  flex-col
  xl:flex-row
  xl:justify-between
  items-end
`

const TitleBox = tw.div`
  w-full
  xl:w-4/5
`

const Description = tw.p`
  text-sm
  w-full
  xl:w-3/5
`



const Building = (props) => {
  const data = useStaticQuery(graphql`
    query {
      buildingCaptions: allBuildingYaml {
        edges {
          node {
            description
            number
          }
        }
      }
      buildingImages: allFile(filter: {
        extension: {regex: "/(jpg)|(png)|(jpeg)/"},
        relativeDirectory: {eq: "buildings"}
      }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 800) {
                originalImg
              }
            }
          }
        }
      }
    }
  `)

  return (

      <Wrapper>
        <TitleBox>
          <HeaderLeft text="Building" />
          <h1
            tw="text-2xl md:tracking-wider md:leading-relaxed py-6">
              Balance old-world elegance with a fresh, contemporary feel
          </h1>
        </TitleBox>
        <FlexBox >
          <Description>
          42 Lyndhurst has been gut-renovated for modern living, but retains the exquisite details of its original construction. Gorgeous stucco walls,
          finely carved mouldings, and extraordinarily airy ceiling heights create the ultimate framework for an elegant and cultured living experience.
          </Description>
          <Carousel slides={data.buildingImages.edges} captions={data.buildingCaptions.edges} />
        </FlexBox>
      </Wrapper>

  )
}

export default Building;
