import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import tw from 'twin.macro'

// import Img from 'gatsby-image'
import 'swiper/css/swiper.css';

// import left, right icon
import LeftArrow from './icons/left_arrow'
import RightArrow from './icons/right_arrow'

const Wrapper = tw.div`
  flex
`

const ArrowWrapper = tw.div`
  hidden
  md:block
  md:my-auto
`

const SliderWrapper = tw.div`
  md:mx-2

`
const CaptionWrapper = tw.div`
  md:mx-10
  text-xs
  font-bold

`
let index = -1



const Carousel = ({slides, captions}) => {

  const [swiper, setSwiper] = useState(null);
  const [caption, setCaption] = useState(captions[index])

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };



  const params = {
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    on:{
      "init": () => {
        let index = -1
      },
      "slideNextTransitionEnd": () => {
        if (index > captions.length - 2) {
          index = 0
          setCaption(captions[index])
        } else {
          index++
          setCaption(captions[index])
        }
      },
      "slidePrevTransitionEnd": () => {
       if (index == 0) {
          index = captions.length - 1
          setCaption(captions[index])
        } else {
          index--
          setCaption(captions[index])
        }
      }
    }
  }


  return (
    <div>
    <Wrapper>
      <ArrowWrapper onClick={goPrev}>
        <LeftArrow fill="black" height="2rem" width="2rem"/>
      </ArrowWrapper>
      <SliderWrapper>
        <Swiper {...params} getSwiper={setSwiper}>
          {
            slides.map((image, index) => (
              <img src={image.node.childImageSharp.fluid.originalImg} alt={index} />
            ))
          }
        </Swiper>
      </SliderWrapper>

      <ArrowWrapper onClick={goNext}>
        <RightArrow fill="black" height="2rem" width="2rem"/>
      </ArrowWrapper>
    </Wrapper>
      <CaptionWrapper>
      {
        caption ?
        (
          <p>FIG {caption.node.number}. - {caption.node.description}</p>
        ) :
        (
          <p>FIG {captions[0].node.number}. - {captions[0].node.description}</p>
        )
      }
      </CaptionWrapper>

    </div>
  )
};

export default Carousel;
