import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Link } from "gatsby"

import styles from "../styles/index.scss"
import StackingPlan from '../images/stacking_plan.png';
import AvData from './av_table_query.js'
import Accordion from './accordion.js'
import HeaderRight from "./header_right"


const AvWrapper = tw.div`
  px-3
  flex
  flex-col
`


const TextBox = tw.div`
  flex
  flex-wrap
  w-3/4
  self-end
  text-right
  justify-end
  py-6
`


const AvLabelandPlanDiv = tw.div`
  flex
  md:relative
  mb-6
  md:mb-12
  max-w-full
  justify-end
  md:justify-center
  items-end
  py-4
`
const RightFrame = tw.div`
  relative
  h-mapmobile
  w-full
  inline-block
`

const AvImage = tw.img`
  absolute
  bottom-0
  right-0
  z-0
  object-contain
  max-h-full
  w-full
`

const AvSvg = tw.svg`
  inline-block
  absolute
  bottom-0
  right-0
  z-10
  max-w-full
  max-h-full
  overflow-hidden
`

const List = tw.ul`
  hidden
  md:flex
  md:flex-col
  text-sm
  ml-1
  font-mono
  font-semibold
  w-full
  text-right
`

const AvTable = styled.table`
  ${tw`hidden`}
  ${tw`md:table`}
  ${tw`items-center`}
  th{
    ${tw`border-b`}
    ${tw`border-black`}
    font-size: 12px;
    height: 60px;
    letter-spacing: .1em;
    padding-left: 20px;
    ${tw`uppercase`}
  }

  td{
    font-size: 14px;
    height: 65px;
    padding-left: 20px;
    ${tw`border-b-0`}
  }

  a{
    ${tw`font-mono`}
    ${tw`font-semibold`}
    ${tw`no-underline`}
    ${tw`text-renavy`}
  }

  a:hover{
    ${tw`font-semibold`}
    ${tw`text-relightbeige`}
  }

`


const AvTableMobile = styled.table`
  ${tw`md:hidden`}

  td{
    font-size: 14px;
    height: 40px;
    padding-left: 10px;
    ${tw`border-b-0`}
  }

  a{
    ${tw`font-mono`}
    ${tw`font-semibold`}
    ${tw`no-underline`}
    ${tw`text-renavy`}
  }

  a:hover{
    ${tw`font-semibold`}
    ${tw`text-relightbeige`}

  }

`


const Availability = () => {
  const setOpacity = (o) => {
    const SelectedElement = document.getElementById(o);
    const Elements = document.getElementsByClassName("zone");
    let ElementArray = Array.from(Elements)
    Array.prototype.map.call(ElementArray, i => {
      i.setAttribute('opacity', '0')
    })
    SelectedElement.getAttribute('opacity') === '0' ? SelectedElement.setAttribute('opacity', '.85') : SelectedElement.setAttribute('opacity', '0');


    const TableRows = document.getElementsByClassName("tablerow")
    let TableRowArray = Array.from(TableRows)
    Array.prototype.map.call(TableRowArray, row => {
      row.dataset.zone === o ? row.className = "tablerow is-selected" : row.className = "tablerow"
    })
    console.log(TableRowArray)

    const Inputs = document.getElementsByName("listitem")
    let InputArray = Array.from(Inputs)
    Array.prototype.map.call(InputArray, input => {
      input.value === o ? input.checked = true : input.checked = false
    })

  }

  return(
    <AvWrapper>
      <HeaderRight text="Homes" />
      <TextBox>
        <h1 tw="text-2xl md:tracking-wider md:leading-relaxed text-right">
          Available for immediate move in
        </h1>
        <div tw="text-sm lg:w-3/5 justify-end">
          Units range from one-bedroom to four-bedroom penthouses. All units have large, open floor plans and flexible configurations.
        </div>
      </TextBox>
      <AvLabelandPlanDiv>
        <List>
            <li>
              <input type="radio" value="one-bed" name="listitem" id="zone-1" onClick={(e) => (setOpacity(e.currentTarget.value))} />
                <label for="zone-1">
                  One Bedroom
                </label>
            </li>
            <li>
              <input type="radio" value="two-bed" name="listitem" id="zone-2" onClick={(e) => (setOpacity(e.currentTarget.value))} />
                <label for="zone-2">
                  Two Bedroom
                </label>
            </li>
            <li>
              <input type="radio" value="three-bed" name="listitem" id="zone-3" onClick={(e) => (setOpacity(e.currentTarget.value))} />
                <label for="zone-3">
                  Three Bedroom
                </label>
            </li>
            <li>
              <input type="radio" value="four-bed" name="listitem" id="zone-4" onClick={(e) => (setOpacity(e.currentTarget.value))} />
                <label for="zone-4">
                  Penthouses
                </label>
            </li>
        </List>
        <RightFrame>
            <AvSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371 500">
                <g id="one-bed" className="zone" opacity="0">
                  <rect x="14" y="344" fill="#7D7C3D" width="343" height="80"></rect>
                </g>
                <g id="two-bed" className="zone" opacity="0">
                  <rect x="14" y="209" fill="#D2943B" width="343" height="135"></rect>
                </g>
                <g id="three-bed" className="zone" opacity="0">
                  <rect x="14" y="116" fill="#8592B4" width="343" height="93"></rect>
                </g>
                <g id="four-bed" className="zone" opacity="0">
                  <rect x="14" y="48" fill="#A69CAC" width="132" height="68"></rect>
                  <rect x="225" y="48" fill="#A69CAC" width="132" height="68"></rect>
                </g>
              </AvSvg>
            <AvImage src={StackingPlan} />
          </RightFrame>
      </AvLabelandPlanDiv>
        <AvTable>
          <thead>
            <tr>
              <th>Unit Type</th>
              <th>Unit</th>
              <th>Baths</th>
              <th>Square Feet</th>
              <th>Price</th>
              <th>Availability</th>
              <th>Floor Plan</th>
            </tr>
          </thead>
          <tbody>
          <AvData setsvg={setOpacity} />
          </tbody>
        </AvTable>
        <AvTableMobile>
        <Accordion setsvg={setOpacity} />
        </AvTableMobile>
    </AvWrapper>


  )

}



export default Availability
