import React, { useState } from 'react'
import { StaticQuery, graphql } from "gatsby";
import tw from 'twin.macro'


const OneBedFilter = ({bedtype}) => (
  <StaticQuery
    query={graphql`
      {
        allAvailabilityYaml(filter: {type: {eq: "one-bed"}}) {
          edges {
            node {
              type
              url
              availability
              SF
              baths
              price
              number
            }
          }
        }
      }
    `}
    render={data =>
    data.allAvailabilityYaml.edges.map((unit) =>
         <div tw="border-t border-renavy grid grid-cols-3 gap-4 mt-3">
          <div tw="col-span-3 py-2">
            <strong tw="uppercase">Unit {unit.node.number}</strong>
          </div>
          <div><strong>SF</strong></div>
          <div><strong>Baths</strong></div>
          <div><strong>Price</strong></div>
          <div>{unit.node.SF}</div>
          <div>{unit.node.baths}</div>
          <div>{unit.node.price}</div>
          <div tw="pb-2 col-span-3"><a href={unit.node.url} target="blank">Download Floor Plan</a></div>
      </div>
    )}
  />
)

export default OneBedFilter
