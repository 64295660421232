import React from "react"

const LeftArrow = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 612 612"
    >
      <g>
        <g id="_x34__7_">
          <g>
            <path
              fill={props.fill}
              d="M439.875,286.875H237.418l78.813-78.814c7.479-7.478,7.479-19.584,0-27.043c-7.478-7.478-19.583-7.478-27.042,0
        l-108.19,108.19c-4.571,4.571-6.005,10.863-4.954,16.792c-1.052,5.929,0.383,12.221,4.973,16.811l108.19,108.19
        c7.478,7.478,19.584,7.478,27.043,0c7.478-7.478,7.478-19.584,0-27.043l-78.833-78.833h202.457
        c10.557,0,19.125-8.568,19.125-19.125C459,295.443,450.432,286.875,439.875,286.875z M306,0C136.992,0,0,136.992,0,306
        c0,168.988,136.992,306,306,306s306-137.012,306-306C612,136.992,475.008,0,306,0z M306,573.75
        C158.125,573.75,38.25,453.875,38.25,306C38.25,158.125,158.125,38.25,306,38.25c147.875,0,267.75,119.875,267.75,267.75
        C573.75,453.875,453.875,573.75,306,573.75z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default LeftArrow
