import React from "react"
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, keywords, url, author }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        const metaAuthor = author || data.site.siteMetadata.author
        const metaImage = image || data.site.siteMetadata.image
        const metaKeywords = keywords || ["luxurious development"]
        return (
          <Helmet
            title={title}
            meta={[
               {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:author`,
                content: metaAuthor,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0 ? {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }: []
            )
          }
          />

        )

      }}
    />
  )
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEO {
    site {
      siteMetadata {
        title
        description
        url
        author
      }
    }
  }

`

// SEO.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string,
//   image: PropTypes.string,
//   article: PropTypes.bool,
// }

// SEO.defaultProps = {
//   title: null,
//   description: null,
//   image: null,
//   article: false,
// }
