import React, { useState } from 'react';
import styled from "@emotion/styled"
import tw from 'twin.macro'

import Img from 'gatsby-image'
import Chevron from './chevron'
// import Slider from 'infinite-react-carousel'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';


const ImgBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;

`

const ImgBoxHeader = tw.div`
  flex
  m-auto
  md:pt-10
`
const ImgBoxTitle = tw.p`
  uppercase
  my-5
  mx-10
  font-semibold
  font-serif
`

const ImgBoxButton = tw.div`
  hidden
  md:block
  md:my-4


`

const ImageBox = ({ title, slides }) => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };


  const params = {
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }

  return (
    <ImgBoxWrapper>
      <ImgBoxHeader>
        <ImgBoxTitle>{title}</ImgBoxTitle>
        <ImgBoxButton onClick={goNext}>
          <Chevron fill="#111" height="2rem" width="2rem" />
        </ImgBoxButton>
      </ImgBoxHeader>
        <Swiper {...params} getSwiper={setSwiper}>
         {
            slides.map((image, index) => (
              <img src={image.node.childImageSharp.fluid.originalImg} alt={index}/>
            ))
          }
        </Swiper>
    </ImgBoxWrapper>
  )
}


export default ImageBox;
