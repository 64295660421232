import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import ImageBox from '../components/image_box'
import HeaderLeft from "./header_left"
import InteriorsFlower from "../images/interiorsflower.png"



const TextBox = tw.div`
  flex
  flex-wrap
  w-full
  text-left
  justify-start
  py-6

`
const FeatureWrapper = tw.div`
  flex
  flex-wrap
  py-4
  mx-3
`

const FeatureBigBox = tw.div`
  flex
  flex-col
  xl:flex-row
`

const FeatureBox = tw.div`
  w-full
  md:px-2
`


const Features = (props) => {

  const data = useStaticQuery(graphql`
    query {
      InteriorsP: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "features/interiors"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                originalImg
              }
            }
          }
        }
      }
      InteriorsL: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "features/interiors2"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                originalImg
              }
            }
          }
        }
      }
      PublicSpaces: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "features/publicspaces"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                originalImg
              }
            }
          }
        }
      }
    }
  `)

  return (
    <FeatureWrapper>
      <HeaderLeft text="Interiors" />
      <TextBox>
        <h1 tw="text-2xl md:tracking-wider md:leading-relaxed text-left">
            A modern oasis, tucked away high above the bustling city</h1>
      </TextBox>
      <FeatureBigBox>
        <FeatureBox>
          <ImageBox title="luxurious finishes" slides={data.InteriorsP.edges} />
        </FeatureBox>
          <div tw="flex flex-col w-full self-end xl:p-6">
            <img src={InteriorsFlower} tw="py-8"/>
            <text tw="text-sm">Thomas Juul-Hansen’s thoughtful interior design honors the materials and scale of the neighborhood’s historic buildings, while creating residences that are fine-tuned to reflect the way we live today. The residences at 42 Lyndhurst offer open, airy spaces that display a meticulous attention to detail—all at a scale that is spacious and livable. The layout of each residence is designed with flexibility in mind, evoking classic architecture, but tailoring it for modern living. Kitchen doors can be closed to create separation between spaces or opened to allow for a relationship between the common rooms. Floor-to-ceiling windows allow light to travel throughout the spaces and Juliet balconies create an elegant connection to the outdoors.</text>
          </div>
      </FeatureBigBox>
        <FeatureBox>
          <ImageBox title="serene interiors" slides={data.InteriorsL.edges} />
        </FeatureBox>
        <FeatureBox>
          <ImageBox title="state-of-the-art amenities" slides={data.PublicSpaces.edges} />
        </FeatureBox>
    </FeatureWrapper>
  )
}

export default Features;
