import React, { useRef, useState, useEffect } from 'react'
import { useViewportScroll, useTransform, motion } from 'framer-motion'
import { render } from "react-dom";


const ParallaxBox = ({
  children,
  easing = "easeOut",
  triggerPoint = .4,
  ...rest
}) => {
  const { scrollY } = useViewportScroll();
  const ref = useRef();
  const [elementTop, setElementTop] = useState(0);
  const [elementBottom, setElementBottom] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const setValues = () => {
      setElementTop(ref.current.offsetTop);
      setElementBottom(ref.current.offsetTop + ref.current.offsetHeight);
      setClientHeight(window.innerHeight);
    };
    setValues();
    document.addEventListener("load", setValues);
    window.addEventListener("resize", setValues);

    return () => {
      document.removeEventListener("load", setValues);
      window.removeEventListener("resize", setValues);
    };
  }, [ref]);

  const transformInitialValue = elementTop-400;
  const transformFinalValue = elementTop-100;

  const opacityRange =  [.2, 1];
  const yOpacityRange = [transformInitialValue, transformFinalValue];
  const opacity = useTransform(
    scrollY,
    yOpacityRange,
    opacityRange,
    "anticipate"
  );

  return (
    <motion.div ref={ref} style={{ opacity }} {...rest}>
      {children}
    </motion.div>
  );
};

export default ParallaxBox
