import React, { useState } from 'react';
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled';
import tw from 'twin.macro';

import OneBedFilter from "./table_queries/one_bed.js";
import TwoBedFilter from "./table_queries/two_bed.js";
import ThreeBedFilter from "./table_queries/three_bed.js";
import FourBedFilter from "./table_queries/four_bed.js";
import RightArrow from './icons/right_arrow';



const AccordionWrapper = tw.div`
  w-full
  md:hidden
`

const AccSummary = tw.div`
  border-b
  border-renavy
  hover:cursor-pointer
  text-sm
  w-full
  py-3
`

const AccDetail = tw.div`

`


const Accordion = ( {setsvg} ) => {

  const [oneBedOpen, setOneBedOpen] = useState(false)
  const [twoBedOpen, setTwoBedOpen] = useState(false)
  const [threeBedOpen, setThreeBedOpen] = useState(false)
  const [penthouseOpen, setPenthouseOpen] = useState(false)

  const setAll = (e) => {
    console.log(e);
    setsvg(e);
    if(e === "one-bed"){
      setOneBedOpen(!oneBedOpen)

    }
    else if (e === "two-bed"){
      setTwoBedOpen(!twoBedOpen)

    }
    else if (e === "three-bed"){
      setThreeBedOpen(!threeBedOpen)

    }
    else if (e === "four-bed"){
      setPenthouseOpen(!penthouseOpen)

    }
  }



  return (
    <AccordionWrapper>
      <section>
        <AccSummary>
          <div className="tablerow" oneBedOpen={oneBedOpen} data-zone="one-bed" onClick = {(e) => setAll(e.currentTarget.dataset.zone)}>
            One Bedroom
            <RightArrow tw="transform rotate-90" fill="#111" height="1.2rem" width="1.2rem"/>
          </div>
        {oneBedOpen ? (<AccDetail><OneBedFilter /></AccDetail>) : (<AccDetail />)}
        </AccSummary>
      </section>

      <section>
        <AccSummary>
          <div className="tablerow" twoBedOpen = {twoBedOpen} data-zone="two-bed" onClick = {(e) => setAll(e.currentTarget.dataset.zone)}>
            Two Bedroom
            <RightArrow tw="transform rotate-90" fill="#111" height="1.2rem" width="1.2rem"/>
          </div>
          {twoBedOpen ? (<AccDetail><TwoBedFilter /></AccDetail>) : (<AccDetail />)}
        </AccSummary>
      </section>

      <section>
        <AccSummary>
          <div className="tablerow" threeBedOpen = {threeBedOpen} data-zone="three-bed" onClick = {(e) => setAll(e.currentTarget.dataset.zone)}>
            Three Bedroom
            <RightArrow tw="transform rotate-90" fill="#111" height="1.2rem" width="1.2rem"/>
          </div>
          {threeBedOpen ? (<AccDetail><ThreeBedFilter /></AccDetail>) : (<AccDetail />)}
        </AccSummary>
      </section>

      <section>
        <AccSummary>
          <div className="tablerow" penthouseOpen = {penthouseOpen} data-zone="four-bed" onClick = {(e) => setAll(e.currentTarget.dataset.zone)}>
            Penthouse
            <RightArrow tw="transform rotate-90" fill="#111" height="1.2rem" width="1.2rem"/>
          </div>
          {penthouseOpen ? (<AccDetail><FourBedFilter /></AccDetail>) : (<AccDetail />)}
        </AccSummary>
      </section>
    </AccordionWrapper>
  )
}


export default Accordion
