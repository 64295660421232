export default [
    {
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#F4F1EC"
            }
        ]
    },
    {
        "featureType": "landscape",
        "stylers": [
            {
                "color": "#F4F1EC"
            },
            {
                "lightness": 0
            }
        ]
    },
    {
        "featureType": "road",
        "stylers": [
            {
                "color": "#D2943B"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "color": "#645c20"
            },
            {
                "lightness": 38
            }
        ]
    },
    {
        "featureType": "water",
        "stylers": [
            {
                "color": "#87BCDE"
            },
            {
                "saturation": 0
            },
            {
                "gamma": 0
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#9F7E69"
            },
            {
                "weight": 1.5
            },
            {
                "visibility": "on"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9F7E69"
            },
            {
                "weight": 1
            },
            {
                "visibility": "off"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "poi.business"
    },
    {
        "featureType": "poi.park",
        "stylers": [
            {
                "color": "#6B9080"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "poi.school",
        "stylers": [
            {
                "color": "#7D84B2"
            },
            {
                "lightness": 40
            }
        ]
    },
    {},
    {
        "featureType": "poi.medical",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#813033"
            },
            {
                "lightness": 40
            },
            {
                "visibility": "off"
            }
        ]
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
        "elementType": "labels"
    },
    {
        "featureType": "poi.sports_complex",
        "stylers": [
            {
                "color": "#916953"
            },
            {
                "lightness": 40
            }
        ]
    },
    {},
    {
        "featureType": "poi.government",
        "stylers": [
            {
                "color": "#5C415D"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "transit.station",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "stylers": [
            {
                "color": "#45536D"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "lightness": 40
            }
        ]
    },
    {},
    {},
    {}
]
