import React, {useState} from "react"
import tw from 'twin.macro'
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { useScrollPosition } from "./use_scroll_position"


const NavItem = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  font-size: .75em;
  color: ${props => (props.hideOnScroll ? "#93A8AC" : "#111")};
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all .2s ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    content: ".";
    color: transparent;
    background: ${props => (props.hideOnScroll ? "transparent" : "#87BCDE")};
    height: .35em;
    margin-bottom: 1px;
    transition: all 0.2s ease-in;
    z-index: -1;
  }

  :hover {
    color: ${props => (props.hideOnScroll ? "#F7F2F3" : "#111")};
    text-decoration: none;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`


const NavbarLinks = () => {

  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(({prevPos, currPos}) => {
    const isShow = currPos.y > -650
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  },
    [hideOnScroll])

  return (
    <>
      <NavItem hideOnScroll = {hideOnScroll} to="/#building">Building</NavItem>
      <NavItem hideOnScroll = {hideOnScroll} to="/#neighborhood">Neighborhood</NavItem>
      <NavItem hideOnScroll = {hideOnScroll} to="/#interiors">Interiors</NavItem>
      <NavItem hideOnScroll = {hideOnScroll} to="/#availability">Availability</NavItem>
      <NavItem hideOnScroll = {hideOnScroll} to="/#developer">Developer</NavItem>
      <NavItem hideOnScroll = {hideOnScroll} tw="font-black" to="/#contact">Contact</NavItem>
    </>
  )
}

export default NavbarLinks

