import React, { useState }from 'react';
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'
import styled from "@emotion/styled"


import HeaderLeft from "./header_left"
import RightArrow from './icons/right_arrow'


const DeveloperWrapper = tw.div`
  flex
  flex-col
  md:flex-row
  my-auto
`

const LeftFrame = tw.div`
  w-full
  md:w-1/4
  md:px-5
  text-sm
  md:text-base
  py-6
  md:px-2
`

const DevAccordionTab = tw.div`
flex
justify-between
align-middle
py-3
border-renavy
border-b
w-full
text-sm
hover:text-reyellow
cursor-pointer
`

const DevAccordionDetail = tw.div`
  text-sm
  font-sans
  px-1
  pt-1

`


const RightFrame = tw.div`
  flex-col
  text-center
  md:w-3/4
`

const PastProjectsDiv = tw.div`
  flex
  flex-col
  md:flex-row
  md:w-full
  items-end
  justify-center
  text-right
`

const ProjectsImage = tw.img`
  w-full
  md:w-2/3
  pb-3
  md:py-0
`

const ProjectsCaption = tw.div`
  w-full
  md:w-1/3
  text-left
  pl-2
  text-sm
`

const ProjectsCaptionHeading = tw.div`
  font-mono
  font-semibold
  uppercase


`


const Developer = (props) => {
  const [approachOpen, setApproachOpen] = useState(false)
  const [partnersOpen, setPartnersOpen] = useState(false)
  const [philanthropyOpen, setPhilanthropyOpen] = useState(false)


  const data = useStaticQuery(graphql`
    query {
      summary: allDeveloperYaml {
        edges {
          node {
            summary
          }
        }
      }
      description: allDeveloperYaml {
        edges {
          node {
            description
          }
        }
      }
    }
  `)




  return(
  <div tw="px-3">
    <HeaderLeft text="Developer" />
    <DeveloperWrapper>
      <LeftFrame>
        <section>
          <DevAccordionTab approachOpen = {approachOpen} onClick = {() => (setApproachOpen(!approachOpen), setPartnersOpen(false), setPhilanthropyOpen(false))}>
            Approach
            <RightArrow tw="transform rotate-90" fill="#111" height="1.2rem" width="1.2rem"/>
          </DevAccordionTab>
          {approachOpen ? <DevAccordionDetail>
            We are community-driven developers. We identify opportunities to improve the communities we develop in through service, philanthropy, and dialogue. We try to source our raw materials and services from the surrounding area, supporting small businesses and creating jobs locally.
            </DevAccordionDetail> : <DevAccordionDetail />}
        </section>
        <section>
          <DevAccordionTab partnersOpen = {partnersOpen} onClick = {() => (setPartnersOpen(!partnersOpen), setApproachOpen(false), setPhilanthropyOpen(false))}>
            Partners
            <RightArrow tw="transform rotate-90"  fill="#111" height="1.2rem" width="1.2rem"/>
          </DevAccordionTab>
          {partnersOpen ? <DevAccordionDetail>We prioritize identifying local talent and services first. Our philosophy is that the best partners are those who have an invested interest in the outcome of our developments. Our funding partners range from government entities to the largest international private equity funds. Our track record and integrity provide us with the opportunity to work with the brightest talent.
          </DevAccordionDetail> : <DevAccordionDetail />}
        </section>
        <section>
          <DevAccordionTab philanthropyOpen = {philanthropyOpen} onClick = {() => (setPhilanthropyOpen(!philanthropyOpen), setPartnersOpen(false), setApproachOpen(false))}>
            Philanthropy
            <RightArrow tw="transform rotate-90" fill="#111" height="1.2rem" width="1.2rem"/>
          </DevAccordionTab>
          {philanthropyOpen ? <DevAccordionDetail>We donate to local food banks, provide excellent training programs for our employees, and participate in actively reducing homelessness in our communities.
          </DevAccordionDetail> : <DevAccordionDetail />}
        </section>
      </LeftFrame>
      <RightFrame>
        <PastProjectsDiv>
          <ProjectsImage src= "https://images.unsplash.com/photo-1554112647-be1efc54727c" />
          <ProjectsCaption>
            <ProjectsCaptionHeading>50 Years of Excellence</ProjectsCaptionHeading>
            We have been in business for half a century, accumulating industry know-how and relationships. We are known for high degrees of integrity in our business, and building thoughtful homes for our neighbors and families.
          </ProjectsCaption>
        </PastProjectsDiv>
      </RightFrame>
    </DeveloperWrapper>
  </div>
  )


}


export default Developer
