import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow
} from 'react-google-maps'
import tw from 'twin.macro'
import styled from '@emotion/styled';



// import hood data
// import hoodsData from "../../data/hoods"
import mapStyles from "./mapStyles"
import HeaderRight from "./header_right"



const Wrapper = tw.div`
  flex
  flex-col
  mx-3
  my-4

`
const MapBox = tw.div`
  h-mapmobile
  md:h-map
  py-6
`


const TextBox = tw.div`
  flex
  flex-wrap
  w-3/4
  text-right
  justify-end
  py-6
  self-end

`

const ImageBox = tw.div`
  flex
  flex-wrap
  w-full
  max-w-full
  align-top
  py-6
`

const MarkerLabel = tw.div`
  text-xs
  font-bold
  md:text-sm
`

const Map = (props) => {
  const [selectedHood, setSelectedHood ] = useState(null)

  const data = useStaticQuery(graphql`
    query {
      allDataYaml {
        nodes {
          hoods {
            name
            lat
            lng
          }
          dev {
            name
            lat
            lng
          }
        }
      }
    }
  `)


  const hoodsData = data.allDataYaml.nodes[0]
  const devData = data.allDataYaml.nodes[0].dev[0]

  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{lat: 22.279902, lng: 114.189674}}
      defaultOptions={{styles: mapStyles }}
    >
      <Marker key={devData.name} position={{
        lat: devData.lat,
        lng: devData.lng
      }}
      icon={{
        url: './logo.svg',
        scaledSize: new window.google.maps.Size(50,50)
      }}
      onClick={()=>{
        setSelectedHood(devData)
      }}
      />
    { hoodsData.hoods.map((hood)=>(
      <Marker key={hood.name} position={{
        lat: hood.lat,
        lng: hood.lng
      }}
      onClick={()=>{
        setSelectedHood(hood)
      }}
      />
    ))}
    {selectedHood && (
      <InfoWindow
        position={{
          lat: selectedHood.lat + 0.007,
          lng: selectedHood.lng
        }}
        onCloseClick={()=>{
          setSelectedHood(null)
        }}
      >
        <MarkerLabel>{selectedHood.name}</MarkerLabel>
      </InfoWindow>
    )}
    </GoogleMap>
  )
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

const HoodMap = (props) => {
  return (

      <Wrapper>
        <HeaderRight text="Neighborhood" />
        <TextBox>
          <h1 tw="text-2xl md:tracking-wider md:leading-relaxed text-right">
            The world at your fingertips</h1>
          <div tw="text-sm lg:w-3/5 justify-end">42 Lyndhurst is steps away from the natural oasis of urban parks, world-class shopping, internationally-acclaimed restaurants, and boundless entertainment options. As part of the historical core of the city, this development is one of the only opportunities to find a personal oasis in the heart of the city.</div>
        </TextBox>

        <ImageBox>
          <div tw="md:w-1/2 p-2">
            <img src="https://images.unsplash.com/photo-1559329007-40df8a9345d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3215&q=80"
              alt="restaurant in neighborhood"
              tw="object-cover"/>
          </div>
          <div tw="flex flex-col md:w-1/2 p-2 items-start">
            <img src="https://images.unsplash.com/photo-1581782222579-51c3d585eda0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3663&q=80"
              alt="night walk past ary galleries"
              tw="object-scale-down pb-4 md:pb-2"
            />
            <img src="https://images.unsplash.com/photo-1559239352-18fe74bbc663?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2167&q=80"
              alt="urban park located minutes from property"
              tw="object-scale-down"
            />
          </div>

        </ImageBox>
        <MapBox>
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
            process.env.GATSBY_APP_GOOGLE_KEY
          }`}
          loadingElement={<div style={{ height: "100%"}} />}
          containerElement={<div style={{ height: "100%"}} />}
          mapElement={<div style={{ height: "100%"}} />}
        />
        </MapBox>
        <ul tw="text-center flex justify-start">
        <li tw="pr-4 flex-col flex text-left">
          <text tw="font-serif font-semibold text-sm pb-1">Nearby Restaurants</text>
          <text tw="font-mono text-sm leading-tight">Piccolo</text>
          <text tw="font-mono text-sm leading-tight">Carbone</text>
          <text tw="font-mono text-sm leading-tight">Sushi Masa</text>
          <text tw="font-mono text-sm leading-tight">Mandarin Grill</text>
          <text tw="font-mono text-sm leading-tight">Espuma</text>
          <text tw="font-mono text-sm leading-tight">La Vache!</text>
        </li>
        <li tw="px-4 flex-col flex text-left ">
          <text tw="font-serif font-semibold text-sm pb-1">Nearby Galleries</text>
          <text tw="font-mono text-sm leading-tight">Gagosian</text>
          <text tw="font-mono text-sm leading-tight">Opera</text>
          <text tw="font-mono text-sm leading-tight">10 Chancery</text>
          <text tw="font-mono text-sm leading-tight">A2Z</text>
          <text tw="font-mono text-sm leading-tight">Aishonanzuka</text>
          <text tw="font-mono text-sm leading-tight">Edouard Malingue</text>
        </li>
        <li tw="px-4 flex-col flex text-left">
          <text tw="font-serif font-semibold text-sm pb-1">Nearby Conveniences</text>
          <text tw="font-mono text-sm leading-tight">Jason's Marketplace</text>
          <text tw="font-mono text-sm leading-tight">MTR Central</text>
          <text tw="font-mono text-sm leading-tight">Tramline Liquor Co.</text>
          <text tw="font-mono text-sm leading-tight">Island Waldorf School</text>
        </li>
        </ul>
      </Wrapper>

  )
}

export default HoodMap;
