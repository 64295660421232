import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import styles from "../styles/index.scss"
import tw from 'twin.macro'
import { Helmet } from "react-helmet"


// Import page component
// import Layout from "../components/layout"
import Navbar from "../components/navbar"
import MainIntro from "../components/main_intro"
import Building from "../components/building"
import ContactForm from "../components/contact_form"
import Availability from "../components/availability"
import HoodMap from "../components/hood_map"
import Developer from "../components/developer"
import Features from "../components/features"
import ParallaxBox from "../components/parallax-box"
import SEO from "../components/seo"



// import ImageBox from "<div className=""></div>/components/image_box"


const Index = ({ data }) => {

  const Body = styled.div`
    min-width: 100%;
    ${tw`bg-rebeige`}

  `


  const Container1 = tw.div`
    relative
    py-3
    md:px-10
    lg:px-32
    xl:px-56
    flex
    flex-col
    align-middle
    justify-center
    py-12
    md:py-24
  `

  const Container2 = tw.div`
    relative
    py-3
    md:px-10
    lg:px-10
    xl:px-32
    flex
    flex-col
    align-middle
    justify-center
    py-12
    md:py-24
  `



  return (
  <div>
    <SEO />
    <Body>
      <Navbar/>
      <MainIntro />
      <ParallaxBox id="#building">
        <Container2 >
          <Building />
        </Container2>
      </ParallaxBox>
      <ParallaxBox id="neighborhood">
        <Container1 >
          <HoodMap />
        </Container1>
      </ParallaxBox>
      <ParallaxBox id="interiors">
        <Container1 >
          <Features />
        </Container1>
      </ParallaxBox>
      <ParallaxBox id="availability">
        <Container1 >
          <Availability />
        </Container1>
      </ParallaxBox>
      <ParallaxBox id="developer">
        <Container1 >
          <Developer />
        </Container1>
      </ParallaxBox>
      <ParallaxBox id="contact">
        <Container1 >
          <ContactForm />
        </Container1>
      </ParallaxBox>

    </Body>
  </div>
  )
}

export const Query = graphql`
  query MainIntroQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`


export default Index
